//mobile
.mobileNav {
    display: none;
    z-index: 100;
    
}

.mobileNavBlock {
    width: 50vw !important;
    cursor: pointer;
}

.mobileCat {
    display: none;
}

.mobileContent {
    display: none;
    height: 300px;
    width: 100vw;
    position: fixed;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.menuContainer {
    display: none;
    height: 100%;
    width: 100vw;
    position: absolute;
    background-color: white;
}

.visible {
    display: inline !important;
}

.invisible {
    display: none !important;
}

.mNavTop {
    height: 20%;
    width: 100vw;
    display: inline-flex;
}

.mNavTopBlock {
    height: 100%;
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
}


.mNavBlock {
    height: 25%;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.mNavBlock p{
    font-family: gitan-latin, sans-serif;
    font-weight: 500 !important;
    font-style: normal;
    font-size: 30px;

}

.mNavList {
    height: 75%;
}

.mNavBlock {
    color: black;
}

.theEnd {
    height: 500px !important;
}
@media only screen and (min-width: 800px) {
    .aboutContent{
        display:none !important;
    }
}


@media only screen and (max-width: 800px) {
    body {
        overflow-y: auto;
    }

    nav {
        display: none !important;
        position: fixed;
        background-color: white;
    }

    .mobileNav {
        display: inline-flex !important;
    }

    .content {
        display: none !important;
    }

    .container {
        width: 100vw !important;
    }

    .sideCat {
        display: none !important;
    }

    .workList {
        width: 100vw;
        margin-top: 300px;
    }

    .workItem {
        height: 300px;
    }

    .mobileCat {
        display: flex;
        height: 150px;
        z-index: 5;

    }

    .cat p {
        transform: rotate(0) !important;
        z-index: -100;
    }

    .mobileContent {
        display: flex;

    }

    .mobileContainer {
        display: flex;
        margin-top: 100px;


    }

    .aboutSection {
        height: auto;
    }

    .aboutItem {
        height: 200px;
    }

    .articleSideBar {
        display: none;
    }

    .articleContent {
        margin-top: 100px;
        margin-left: 0px !important;
        width: 100vw !important;
    }

    .hoverPT, .hoverLW, .hoverFLUX, .hoverCO, .hoverCATB, .hoverBA19, .hoverCODE, .hoverDRUM{
        width: 100vw;
        height: 100%;
        position: absolute;
        z-index: 10;
    }

    .email {
        border-top: 2px solid black;
    }

}